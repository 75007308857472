<template>
  <div class="privacy">
    <NavBar />
    <div class="bg">
      <main class="container mx-auto max-w-7xl px-5">
        <div>
          <div class="h2 text-xl font-bold mb-5">プライバシーポリシー</div>

          <dl class="text-left">
            <p>
              株式会社アマリル（以下、「当社」といいます。）は、当社が運営するウェブサイト上で提供するサービス（以下、「本サービス」といいます。）における全ての利用者（以下、「ユーザー」といいます。）の個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
            </p>

            <dt class="font-bold my-2">第1条（個人情報及び個人データ等）</dt>
            <dd>
              <ol class="list-inside list-decimal">
                <li>
                  「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、連絡先その他の記述等により特定の個人を識別できる情報（他の情報と容易に照合することができそれにより特定の個人を識別することができることとなるものを含む。）及び容貌、指紋、声紋にかかるデータ、及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別符号）が含まれるものを指します。
                </li>
                <li>
                  「個人データ」とは、個人情報保護法にいう「個人データ」を指すものとし、個人情報データベース等を構成する個人情報をいう。
                </li>
                <li>
                  「保有個人データ」とは、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止を行うことのできる権限を有する個人データであって、その存否が明らかに公益その他の利益が害されるものとして以下に定めるもの以外のものをいう。
                  <ol class="ol-brackets">
                    <li>
                      当該個人データの存否が明らかになることにより、本人又は第三者の生命、身体又は財産に危害が及ぶおそれがあるもの
                    </li>
                    <li>
                      当該個人データの存否が明らかになることにより、違法又は不当な行為を助長し、又は誘発するおそれがあるもの
                    </li>
                    <li>
                      当該個人データの存否が明らかになることにより、国の安全が害されるおそれ、他国若しくは国際機関との信頼関係が損なわれるおそれ又は他国若しくは国際機関との交渉上不利益を被るおそれがあるもの
                    </li>
                    <li>
                      当該個人データの存否が明らかになることにより、犯罪の予防、鎮圧又は捜査その他の公共の安全と秩序の維持に支障が及ぶおそれがあるもの
                    </li>
                  </ol>
                </li>
              </ol>
            </dd>

            <dt class="font-bold my-2">
              第2条（個人情報及び端末情報等の収集）
            </dt>
            <dd>
              <ol class="list-inside list-decimal">
                <li>
                  当社は、ユーザーが本サービスを利用する際又は利用登録をする際に氏名、生年月日、住所、メールアドレスなどの個人情報をお尋ねすることがあります。また、ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を、当社の提携先（情報提供元、広告主、広告配信先などを含みます。以下、｢提携先｣といいます。）などから収集することがあります。
                </li>
                <li>
                  当社は、当社が運営するウェブサイト又はアプリにおいて以下の情報を取得し、当社に送信します。
                  <ol class="ol-brackets">
                    <li>
                      取得する情報
                      <ol class="ol-circle">
                        <li>
                          <span>&#9312;</span
                          >端末を識別する情報（機種、ＯＳのバージョン）
                        </li>
                        <li>
                          <span>&#9313;</span
                          >ユーザーの利用環境（ブラウザ、ＯＳ、ＩＰアドレス）
                        </li>
                        <li>
                          <span>&#9314;</span>広告識別子（ＩＤＦＡ、ＡＡＩＤ）
                        </li>
                        <li>
                          <span>&#9315;</span
                          >ユーザーがウェブサイト又はアプリで閲覧・利用した記録（閲覧したＵＲＬ、時刻等）
                        </li>
                      </ol>
                    </li>
                    <li>
                      各情報は、ユーザーによるウェブサイトの閲覧及びアプリの利用に伴い、cookie、アプリケーション、端末を示すＩＤ等に紐づけて自動的に記録されます。
                    </li>
                  </ol>
                </li>
              </ol>
            </dd>

            <dt class="font-bold my-2">
              第3条（個人情報を収集・利用する目的）
            </dt>
            <dd>
              当社は、取得した個人情報を以下の範囲内でのみ利用します。
              <ol class="list-inside list-decimal">
                <li>
                  当社サービスの提供・運営のため、ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）
                </li>
                <li>
                  ユーザーが利用中のサービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため
                </li>
                <li>
                  メンテナンス、重要なお知らせなど必要に応じたご連絡のため、利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの特定をし、ご利用をお断りするため
                </li>
                <li>
                  ユーザーにご自身の登録情報の閲覧や変更、削除、ご利用状況の閲覧を行っていただくため
                </li>
                <li>
                  ユーザーが掲載情報及び投稿の訂正、削除等を依頼する場合に、当社が、削除を依頼したユーザーへのご連絡を行うため（本人確認を行うことを含む）
                </li>
                <li>
                  サービス利用の利便性向上のため<br />
                  （例）ログイン状態を保持し、再度の認証を不要にするため
                </li>
                <li>
                  提供するサービスの効果検証や、改善・新サービスの開発のため<br />
                  （例）ユーザーの興味、関心その他の属性をマーケティングデータとして分析します
                </li>
                <li>上記の利用目的に付随する目的</li>
              </ol>
            </dd>

            <dt class="font-bold my-2">第4条（利用目的の変更）</dt>
            <dd>
              <ol class="list-inside list-decimal">
                <li>
                  当社は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。
                </li>
                <li>
                  利用目的の変更を行った場合には、変更後の目的について、当社所定の方法により、ユーザーに通知し、又は本ウェブサイト上に公表するものとします。
                </li>
              </ol>
            </dd>

            <dt class="font-bold my-2">第5条（個人データの管理）</dt>
            <dd>
              <ol class="list-inside list-decimal">
                <li>
                  当社は、利用目的の達成に必要な範囲内において、個人データを正確かつ最新の内容に保つとともに、利用する必要がなくなったときは、当該個人データを遅滞なく消去するよう努めるものとします。
                </li>
                <li>
                  当社は、その取り扱う個人データの漏えい、滅失又は毀損の防止その他の個人データの安全管理のために必要かつ適切な措置を講じます。
                </li>
                <li>
                  当社は、その従業者に個人データを取り扱わせるに当たっては、当該個人データの安全管理が図られるよう、当該従業者に対する必要かつ適切な監督を行います。
                </li>
                <li>
                  当社は、個人データの取扱いの全部又は一部を委託する場合は、その取扱いを委託された個人データの安全管理が図られるよう、委託を受けた者に対する必要かつ適切な監督を行ないます。
                </li>
              </ol>
            </dd>

            <dt class="font-bold my-2">第6条（個人データの第三者提供）</dt>
            <dd>
              <ol class="list-inside list-decimal">
                <li>
                  当社は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人データを提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
                  <ol class="ol-brackets">
                    <li>
                      人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
                    </li>
                    <li>
                      公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
                    </li>
                    <li>
                      国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
                    </li>
                    <li>
                      予め次の事項を告知あるいは公表し、かつ当社が個人情報保護委員会に届出をしたとき
                      <ol style="list-style-type: katakana" class="list-inside">
                        <li>利用目的に第三者への提供を含むこと</li>
                        <li>第三者に提供される個人データの項目</li>
                        <li>第三者に提供される個人データの取得の方法</li>
                        <li>第三者への提供の手段又は方法</li>
                        <li>
                          本人の求めに応じて個人データの第三者への提供を停止すること
                        </li>
                        <li>本人の求めを受け付ける方法</li>
                        <li>
                          その他個人の権利利益を保護するために必要なものとして個人情報保護委員会規則で定める事項
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  前項の定めにかかわらず、次に掲げる場合には、当該個人データの提供先は第三者に該当しないものとします。
                  <ol class="ol-brackets">
                    <li>
                      当社が利用目的の達成に必要な範囲内において個人データの取扱いの全部又は一部を委託する場合
                    </li>
                    <li>
                      合併その他の事由による事業の承継に伴って個人データが提供される場合
                    </li>
                    <li>
                      個人データを特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人データの項目、共同して利用する者の範囲、利用する者の利用目的及び当該個人データの管理について責任を有する者の氏名又は名称について、あらかじめ本人に通知し、又は本人が容易に知り得る状態に置いた場合
                    </li>
                  </ol>
                </li>
              </ol>
            </dd>

            <dt class="font-bold my-2">第7条（保有個人データの開示）</dt>
            <dd>
              <ol class="list-inside list-decimal">
                <li>
                  当社は、本人から保有個人データの開示を求められたときは、本人に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部又は一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。
                  <ol class="ol-brackets">
                    <li>開示請求の対象が「保有個人データ」に該当しない場合</li>
                    <li>
                      本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
                    </li>
                    <li>
                      当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
                    </li>
                    <li>その他法令に違反することとなる場合</li>
                  </ol>
                </li>
                <li>
                  前項の定めにかかわらず、履歴情報及び特性情報などの個人データ以外の情報については、原則として開示いたしません。
                </li>
                <li>
                  保有個人データの開示に際しては、1件あたり1,000円（消費税別）の手数料を申し受けます。手数料は、当社指定の金融機関にお支払いください。なお、金融機関の手数料は開示請求を行う本人又は代理人がご負担ください。
                </li>
              </ol>
            </dd>

            <dt class="font-bold my-2">
              第8条（保有個人データの訂正及び削除）
            </dt>
            <dd>
              <ol class="list-inside list-decimal">
                <li>
                  ユーザーは、当社の保有する自己の保有個人データが誤った情報である場合には、当社が定める手続きにより、当社に対して当該保有個人データの訂正、追加又は削除（以下、「訂正等」といいます。）を請求することができます。
                </li>
                <li>
                  当社は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該保有個人データの訂正等を行うものとします。
                </li>
                <li>
                  当社は、前項の規定に基づき訂正等を行った場合、又は訂正等を行わない旨の決定をしたときは遅滞なく、これをユーザーに通知します。
                </li>
              </ol>
            </dd>

            <dt class="font-bold my-2">第9条（保有個人データの利用停止等）</dt>
            <dd>
              <ol class="list-inside list-decimal">
                <li>
                  当社は、本人から、保有個人データが、利用目的の範囲を超えて取り扱われているという理由、又は不正の手段により取得されたものであるという理由により、その利用の停止又は消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行います。
                </li>
                <li>
                  前項の調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく、当該保有個人データの利用停止等を行います。
                  当社は、前項の規定に基づき利用停止等を行った場合、又は利用停止等を行わない旨の決定をしたときは、遅滞なく、これをユーザーに通知します。
                </li>
                <li>
                  前2項にかかわらず、利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じるものとします。
                </li>
              </ol>
            </dd>

            <dt class="font-bold my-2">第10条（プライバシーポリシーの変更）</dt>
            <dd>
              本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく、変更することができるものとします。
              当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
            </dd>

            <dt class="font-bold my-2">個人情報取扱事業者</dt>
            <dd>
              <div class="">
                <div class="flex">
                  <span class="w-1/6">名称</span>
                  <span class="w-5/6">株式会社アマリル</span>
                </div>
                <div class="flex">
                  <span class="w-1/6">代表者</span>
                  <span class="w-5/6">代表取締役</span>
                </div>
                <div class="flex">
                  <span class="w-1/6">所在</span>
                  <span class="w-5/6"
                    >東京都千代田区平河町1丁目3番6号BIZMARKS麹町2階</span
                  >
                </div>
              </div>

              <p>お問い合せ窓口</p>
              <p>
                開示、訂正、利用停止等の請求、個人情報保護に関する苦情・相談等、本ポリシーに関するお問合せは以下の窓口で承ります。
              </p>
              <p>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSeYSwFx6mntAIIxEXcLfB1oaA-mgDiqbenPctjVcvvAFTJKKQ/viewform"
                  class="underline text-bold"
                  >《個人情報に関するお客様ご相談窓口》</a
                >
              </p>
            </dd>
          </dl>
        </div>
      </main>
    </div>
    <Footers />
  </div>
</template>

<script>
// @ is an alias to /src

import Footers from "@/components/Footers.vue";
import NavBar from "@/components/NavBar.vue";

export default {
  name: "Privacy",
  components: {
    Footers,
    NavBar,
  },
};
</script>

<style>
.ol-brackets {
  margin-left: 2.5em;
}

.ol-brackets > li {
  list-style-type: none;
  counter-increment: cnt;
  position: relative;
}

.ol-brackets > li:before {
  content: "(" counter(cnt) ")";
  display: inline-block;
  margin-left: -3.5em;
  width: 3em;
  text-align: right;
  position: absolute;
  top: 0;
  left: 0;
}

.ol-circle {
  position: relative;
  margin: 0;
  padding: 0;
}
.ol-circle > li {
  list-style: none;
  list-style-position: outside;
  margin: 0;
  padding-left: 1.25em;
}
.ol-circle > li span {
  position: absolute;
  left: 0;
  margin: 0;
}
</style>
