<template>
  <div class="home">
    <Top />
    <Footers />
  </div>
</template>

<script>
// @ is an alias to /src
import Top from "@/components/Top.vue";
import Footers from "@/components/Footers.vue";

export default {
  name: "Home",
  components: {
    Top,
    Footers,
  },
};
</script>
