<template>
  <div class="footer py-10 px-4">
    <div class="flex flex-wrap justify-center max-w-5xl m-auto">

      <div id="pc-footer" class="grid grid-cols-6">
        <div>
          <div class="flex flex-col text-left">
          <div class="my-3"><router-link to="/privacy">プライバシーポリシー</router-link></div>
          <div><a href="https://docs.google.com/forms/d/e/1FAIpQLSeYSwFx6mntAIIxEXcLfB1oaA-mgDiqbenPctjVcvvAFTJKKQ/viewform">お問合せ</a></div>
          </div>
        </div>

        <div class="flex flex-col text-left">

        </div>

        <div></div>
        <div></div>
        <div class="col-span-2 text-left">
          <div>株式会社アマリル</div>
          <div>
            〒102-0093<br />東京都千代田区平河町１丁目３−６ BIZMARKS麹町2F
          </div>
        </div> 
      </div> 

    </div>

    <div id="mb-footer" class="">
      <div class="flex flex-col text-left">
        <div class="my-3"><router-link to="/privacy">プライバシーポリシー</router-link></div>
        <div><a href="https://docs.google.com/forms/d/e/1FAIpQLSeYSwFx6mntAIIxEXcLfB1oaA-mgDiqbenPctjVcvvAFTJKKQ/viewform">お問合せ</a></div>
      </div>
    </div> 

    <div class="flex justify-between max-w-5xl m-auto items-end mt-10">
      <div>
        <img src="@/assets/img/top/logo-white.svg" />
      </div>
      <div>©️Amaril Inc</div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Footers",
};
</script>

<style>
.footer {
  font-family: "ヒラギノ角ゴ StdN W4", HiraKakuProN-W3, 游ゴシック, "Yu Gothic", メイリオ, Meiryo, Verdana, Helvetica, Arial, sans-serif;
}

@media (max-width: 640px ) {
  #pc-footer {
    display: none;
  }
}

@media (min-width: 640px ) {
  #mb-footer {
    display: none;
  }
}


</style>
