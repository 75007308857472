import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Privacy from "@/views/Privacy.vue";
import "@/index.css";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
