<template>
<div>
  <div class="sm:flex sm:justify-between sm:items-center sm:px-4 sm:py-3 bg sm:pt-10">
    <div class="flex items-center justify-between px-4 py-3 sm:p-0">
      <div>
        <a href="/"><img class="h-8" src="@/assets/img/top/logo.png" alt="Amaril"></a>
      </div>
      <div class="sm:hidden">
        <button @click="isOpen = !isOpen" type="button" class="block focus:outline-none">
          <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
            <path v-if="isOpen" fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
            <path v-if="!isOpen" fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
          </svg>
        </button>
      </div>
    </div>
    <nav class="px-2 pt-2 pb-4 sm:flex sm:p-0" :class="isOpen ? '' : 'hidden'">
      <router-link class="mt-1 block px-2 py-1 font-semibold rounded sm:mt-0 sm:ml-2" to="/privacy">プライバシーポリシー</router-link>
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSeYSwFx6mntAIIxEXcLfB1oaA-mgDiqbenPctjVcvvAFTJKKQ/viewform" class="mt-1 block px-2 py-1 font-semibold rounded sm:mt-0 sm:ml-2">お問合せ</a>
    </nav>
  </div>

  <Teleport to="body">
    <div v-if="isOpen" class="fixed flex inset-0 flex-col menu_bg">
      <div class="flex items-center justify-between px-4 py-3 sm:p-0">
        <div>
          <a href="/"><img class="h-8" src="@/assets/img/top/logo-white.svg" alt="Amaril"></a>
        </div>
        <div class="sm:hidden">
          <button @click="isOpen = !isOpen" type="button" class="block focus:outline-none">
            <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
              <path v-if="isOpen" fill="#fff" fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
            </svg>
          </button>
        </div>
      </div>
      <nav class="px-2 pt-10 sm:flex sm:p-0 text-white" :class="isOpen ? '' : 'hidden'">
        <router-link class="mt-1 block px-2 py-1 font-semibold rounded sm:mt-0 sm:ml-2" to="/privacy">プライバシーポリシー</router-link>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSeYSwFx6mntAIIxEXcLfB1oaA-mgDiqbenPctjVcvvAFTJKKQ/viewform" class="mt-1 block px-2 py-1 font-semibold rounded sm:mt-0 sm:ml-2">お問合せ</a>
      </nav>
    </div>
  </Teleport>

</div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      isOpen: false,
    }
  },
};
</script>

<style>
nav {
  font-color: #242424;
  font-family: "ヒラギノ角ゴ StdN W4", HiraKakuProN-W3, 游ゴシック, "Yu Gothic", メイリオ, Meiryo, Verdana, Helvetica, Arial, sans-serif;
}

.bg {
  background-color: #F2F2ED;
}

.menu_bg {
  background-color: #F7A86B;
}
</style>
