<template>
<div>

  <link rel="icon" href="@/assets/img/top/favicon1.png" />
  
  <NavBar />

  <div class="top">
    <div class="max-w-5xl m-auto">

      <section class="max-w-5x">
        <div class="relative max-w-5x">
          <img src="@/assets/img/top/flower.png" alt="Your Image" class="mr-4">
            <div class="absolute top-1/2 sm:left-1/2 flex items-center h-full hidden sm:inline-block">
              <h2 class="mission text-6xl break-words font-bold tracking-wider uppercase">
                <p>Contribution</p>
                <p>to a Sustainable Society</p>
              </h2>
            </div>
        </div>

        <h2 class="mission text-2xl break-words font-bold tracking-wider uppercase text-left sm:hidden">
          <p>Contribution</p>
          <p>to a Sustainable Society</p>
        </h2>
      </section>

      <section class="max-w-5xl clear-both sm:px-4">
        <h2 class="font-sans text-3xl my-10 text-left">Service</h2>
        <div class="sm:flex sm:pt-10 pb-24">
          <div class="flex-1">
            <div class="h-40">
              <img class="m-auto" src="@/assets/img/top/icon_1.png" />
            </div>
            <div class="my-3 service-title ">受託開発</div>
            <div></div>
          </div>

          <div class="flex-1">
            <div class="h-40">
              <img class="m-auto" src="@/assets/img/top/icon_2.png" />
            </div>
            <div class="my-3 service-title ">動画編集</div>
            <div></div>
          </div>
        </div>
      </section>

    </div>
  </div>
</div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
export default {
  name: "Top",
  components: {
    NavBar,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top {
  background: #F2F2ED;
}

h2 {
  font-family: 'Outfit', sans-serif;
  color: #F7A86B;
}

.mission {
  font-family: 'Outfit', sans-serif;
  color: #F7A86B;
}

.service-title {
  font-family: "ヒラギノ角ゴ Pro W3", sans-serif;
}

.service-link {
  font-family: "ヒラギノ角ゴ Pro W3", sans-serif;
  color: #F7A86B;
}


</style>
